<template>
    <div class="page-layout-container">
        <div class="page-layout-main">
            <FullPageLoadingSpinner :show="pageIsLoading" />

            <template v-if="!pageIsLoading">
                <PageHeading :breadcrumbs="breadcrumbs" title="Tenants" />

                <div class="tenants-content">
                    <Datatable
                        class="tenants-table"
                        :data="{ items: tenantList }"
                        :columns="tableColumns"
                        @rowClick="rowOnClick"
                    >
                        <template v-slot:action>
                            <div class="align-right">
                                <IconButton icon="settings" />
                            </div>
                        </template>
                    </Datatable>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import PageHeading from '@/components/ui/PageHeading';
import FullPageLoadingSpinner from '@/components/ui/FullPageLoadingSpinner';
import Datatable from '@/components/Datatable';
import IconButton from '@/components/ui/IconButton';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';

export default {
    name: 'TenantList',
    components: {
        PageHeading,
        FullPageLoadingSpinner,
        Datatable,
        IconButton,
    },
    data() {
        return {
            breadcrumbs: [],
            tableColumns: [
                {
                    value: 'name',
                    header: 'Name',
                    type: 'text',
                    width: '20%',
                },
                {
                    value: 'code',
                    header: 'Code',
                    type: 'text',
                    width: '65%',
                },
                {
                    value: 'action',
                    header: '',
                    type: 'slot',
                    width: '15%',
                    class: 'text-left',
                },
            ],
        };
    },
    computed: {
        ...mapState('tenant', ['tenantList']),
        pageIsLoading() {
            return this.$wait.is(LoadingFlag.TenantsGet);
        },
    },
    created() {
        this.setBreadcrumbs();
        this.getTenants();
    },
    beforeDestroy() {
        this.clearTenants();
    },
    methods: {
        ...mapActions('tenant', ['getTenants', 'clearTenants']),
        async rowOnClick(event, item) {
            this.$router.push({
                name: 'tenant',
                params: { tenantCode: item.code },
            });
        },
        setBreadcrumbs() {
            this.breadcrumbs = [new Breadcrumb('Tenants', { name: 'tenant-list' })];
        },
    },
};
</script>

<style lang="scss">
.tenants-table {
    tr {
        cursor: pointer;
    }
}
</style>
